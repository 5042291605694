import { useState } from 'react'
import { Input, Select, Table, Button } from 'antd'
import { Flex } from '@components/flex'
import CommonDetail from '@components/common/CommonDetail'
import { useDataTable, useRowsNColumns } from '@components/imagemap/right-panel/hooks'
import { uniq } from 'lodash'
import { DataSourceDto } from '@/interfaces/data'
import MultiSelectDropdown from '@components/common/MultiSelectDropdown'
import DropDownMenu from '@components/imagemap/right-panel/data-source/DropDownMenu'

interface DataEntry {
  index: number,
  layout: number
  dataTable: number
  columns: number[]
  rows: number[]
  rowTags?: string[]
}

type Props = {
  dataSource: DataSourceDto
  layouts: { id: number; kind: string }[]
  onFinish: (values: DataEntry[]) => void
  onCancel: () => void
  Layout: any
}

const dataLayouts = [
  'Data Slide [col]',
  'Data Slide [col stacked %]',
  'Data Slide [bar]',
  'Data Slide [bar stacked %]',
  'Data Slide [line]'
]

export const ProjectCustomizer = ({ dataSource, layouts, onFinish, onCancel, Layout }: Props) => {
  const selectColumnsMode = false ? '' : 'multiple'
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const dataTables = dataSource?.dataSections?.map(section => section.dataTables)?.flat() || []
  const [columnsDropdownVisible, setColumnsDropdownVisible] = useState(false)
  const [rowsDropdownVisible, setRowsDropdownVisible] = useState(false)
  const [dataTypesDropdownVisible, setDataTypesDropdownVisible] = useState(false)

  const { rows, columns } = useRowsNColumns(selectedItem?.dataTable)
  const { data: table } = useDataTable(selectedItem?.dataTable)
  const [antdTableData, setAntdTableData] = useState([{ index: 1 }])
  console.log("table", table)
  const filteredRows = (activeRowTags: any[]) => {
    return rows.filter((row, rowIndex) => {
      if (!table?.rowsMeta) {
        return true
      }
      return activeRowTags.includes(table.rowsMeta[rowIndex])
    })
  }
  const dataLayoutSelected = (index: number) => {
    return layouts.filter(layout => layout.id === index && dataLayouts.includes(layout.kind)).length > 0
  }

  const handleChangeTableRow = (field: string, index: number, value: any) => {
    let _antdTableData = [...antdTableData ?? []]?.map((item => {
      if (item?.index == index) {
        const _selectedItem = {
          ...item,
          [field]: value
        }
        setSelectedItem(_selectedItem)
        return _selectedItem
      }
      return item
    }))
    setAntdTableData(_antdTableData);
  }

  const handleAddSlide = () => {
    setAntdTableData([...antdTableData, { index: antdTableData.length + 1 }])
  }

  const handleDeleteSlide = () => {
    antdTableData.length > 0 && setAntdTableData(antdTableData.slice(0, antdTableData.length - 1))
  }

  const onSendEntries = () => {
    onFinish(antdTableData as DataEntry[])
  }

  const antdTableColumns = [
    {
      title: 'Slide',
      dataIndex: 'index',
      key: '1'
    },
    {
      title: 'Slide layout',
      dataIndex: 'slideLayout',
      key: '2',
      render: (text, record) => {
        return (
          <Select
            placeholder={'Select layout'}
            style={{ minWidth: 200 }}
            value={record?.layout ?? ""}
            onChange={e => handleChangeTableRow("layout", record?.index ?? 0, e)}
            disabled={record.index !== antdTableData.length}
          >
            {layouts.map(layout => (
              <Select.Option key={layout.id} value={layout.id}>
                {layout.kind}
              </Select.Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'Slide Title',
      dataIndex: 'slideTitle',
      key: '3',
      render: (text, record) => (
        <Input
          placeholder={'Slide Title'}
          style={{ minWidth: 100 }}
          value={record?.slideTitle ?? ""}
          onChange={(e: any) => handleChangeTableRow("slideTitle", record?.index ?? 0, e.target.value)}
          disabled={record.index !== antdTableData.length}
        />
      )
    },
    {
      title: 'Table',
      dataIndex: 'table',
      key: '4',
      render: (text, record) => (
        <Select
          placeholder={'Search tables'}
          style={{ minWidth: 200 }}
          value={record?.dataTable ?? ""}
          onChange={(e: any) => handleChangeTableRow("dataTable", record?.index ?? 0, e)}
          disabled={record.index !== antdTableData.length || !dataLayoutSelected(record?.layout ?? 0)}
        >
          {dataTables.map(table => (
            <Select.Option key={table.id} value={table.id}>
              {table.name}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Data types',
      dataIndex: 'dataTypes',
      key: '5',
      render: (text, record) => {
        return (
          <MultiSelectDropdown
            onChange={(e: any) => handleChangeTableRow("rowTags", record?.index ?? 0, e)}
            mode={selectColumnsMode}
            optionFilterProp="children"
            value={record?.rowTags ?? []}
            dropdownRender={menu =>
              selectColumnsMode ? (
                <DropDownMenu
                  menu={menu}
                  onSelect={() => handleChangeTableRow("rowTags", record?.index ?? 0, uniq(table?.rowsMeta ?? []))}
                  onClear={() => handleChangeTableRow("rowTags", record?.index ?? 0, [])}
                  onOk={() => {
                    setDataTypesDropdownVisible(false)
                  }}
                  onCancel={() => {
                    setDataTypesDropdownVisible(false)
                  }}
                />
              ) : (
                menu
              )
            }
            align="left"
            visible={record.index === antdTableData.length && dataTypesDropdownVisible}
            setVisible={setDataTypesDropdownVisible}
            placeholder={'Filter row tags'}
            style={{ minWidth: 200, maxWidth: 500, }}
            disabled={record.index !== antdTableData.length || !dataLayoutSelected(record?.layout ?? 0)}
          >
            {uniq(table?.rowsMeta ?? []).map(row => (
              <Select.Option style={{ whiteSpace: 'normal', height: 'auto' }} value={row} key={row}>
                {row}
              </Select.Option>
            ))}
          </MultiSelectDropdown>
        )
      }
    },
    {
      title: 'Columns',
      dataIndex: 'columns',
      key: '6',
      render: (text, record) => {
        return (
          <MultiSelectDropdown
            onChange={(e: any) => handleChangeTableRow("columns", record?.index ?? 0, e)}
            mode={selectColumnsMode}
            optionFilterProp="children"
            value={record?.columns ?? []}
            dropdownRender={menu =>
              selectColumnsMode ? (
                <DropDownMenu
                  menu={menu}
                  onSelect={() => handleChangeTableRow("columns", record?.index ?? 0, columns?.map(col => col.index))}
                  onClear={() => handleChangeTableRow("columns", record?.index ?? 0, [])}
                  onOk={() => setColumnsDropdownVisible(false)}
                  onCancel={() => setColumnsDropdownVisible(false)}
                />
              ) : (
                menu
              )
            }
            align="left"
            visible={record.index === antdTableData.length && columnsDropdownVisible}
            setVisible={setColumnsDropdownVisible}
            placeholder="Search columns"
            style={{ minWidth: 200, maxWidth: 500, }}
            disabled={record.index !== antdTableData.length || !dataLayoutSelected(record?.layout ?? 0)}
          >
            {columns.map(column => (
              <Select.Option
                style={{ whiteSpace: 'normal', height: 'auto' }}
                value={column.index}
                key={column.name}
              >
                {column.name}
              </Select.Option>
            ))}
          </MultiSelectDropdown>
        )
      }
    },
    {
      title: 'Rows',
      dataIndex: 'rows',
      key: '7',
      render: (text, record) => {
        return (
          <MultiSelectDropdown
            onChange={(e: any) => handleChangeTableRow("rows", record?.index ?? 0, e)}
            mode={selectColumnsMode}
            optionFilterProp="children"
            value={record?.rows ?? []}
            dropdownRender={menu =>
              selectColumnsMode ? (
                <DropDownMenu
                  menu={menu}
                  onSelect={() => handleChangeTableRow("rows", record?.index ?? 0, rows.map(row => row.index))}
                  onClear={() => handleChangeTableRow("rows", record?.index ?? 0, [])}
                  onOk={() => setRowsDropdownVisible(false)}
                  onCancel={() => {
                    setRowsDropdownVisible(false)
                  }}
                />
              ) : (
                menu
              )
            }
            align="left"
            visible={record.index === antdTableData.length && rowsDropdownVisible}
            setVisible={setRowsDropdownVisible}
            placeholder="Search rows"
            style={{ minWidth: 100, maxWidth: 500, }}
            disabled={record.index !== antdTableData.length || !dataLayoutSelected(record?.layout ?? 0)}
          >
            {filteredRows(record?.rowTags ?? []).map(row => (
              <Select.Option
                style={{ whiteSpace: 'normal', height: 'auto' }}
                value={row.index}
                key={row.name}
              >
                {row.name}
              </Select.Option>
            ))}
          </MultiSelectDropdown>
        )
      }
    },
  ]

  return (
    <Layout
      previous={<Button onClick={() => onCancel()}>Previous</Button>}
      next={
        <Button type="primary" onClick={() => onSendEntries()}>
          Next
        </Button>
      }
    >
      <CommonDetail
        className="data-uploader"
        style={{
          minWidth: 'auto',
        }}
      >
        <div className="slide__text">
          <h1 className="slide__title">Customise Presentation</h1>
        </div>
        <Flex style={{ padding: '6px 0' }} justifyContent="flex-start">
          <Button type="primary" onClick={handleAddSlide}>
            Add slide
          </Button>
          <Button disabled={antdTableData.length < 1} onClick={handleDeleteSlide}>
            Delete slide
          </Button>
        </Flex>
        <Flex justifyContent='center'>
          <Table
            dataSource={antdTableData}
            columns={antdTableColumns}
            scroll={{ x: 'max-content' }} // Add horizontal scroll
          />
        </Flex>
      </CommonDetail>
    </Layout>
  )
}
